<template>
  <div class="login">
    <div class="header-title">
      <p class="title">Login Password</p>
      <p v-if="isIOS" class="title" @click="goRegister">Daftar</p>
    </div>
    <form class="login-body">
      <div class="input-block">
        <img src="@/assets/img/phone-icon.png" />
        <input v-model.trim="phone" type="tel" maxlength="13" autofocus placeholder="Masukkan nomor handphone dengan awalan 08">
      </div>
      <div class="input-block">
        <img src="@/assets/img/password-icon.png" />
        <input v-model.trim="password" maxlength="8" type="tel" class="password" placeholder="Masukkan 8 digit angka password">
      </div>
      <div class="forget-password" @click="forgetPassword">Lupa Password？</div>
      <button type="button" class="login-btn" @click="onSubmit">Login Password</button>
      <button type="button" class="login-btn" @click="goMessageLogin">Login dengan SMS</button>
    </form>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { Toast } from 'vant'
import request from '@/utils/request'
import { validatePhoneNumber, validatePassword } from '@/utils/validator'
import { isIOS } from '@/utils/detect'
import webviewBack from '@/mixins/webviewBack'

export default {
  name: 'Login',
  mixins: [webviewBack],
  data () {
    return {
      phone: '',
      password: '',
      isIOS
    }
  },
  methods: {
    forgetPassword () {
      const { query } = this.$route
      this.$router.push({
        path: '/forgetPassword',
        query
      })
    },
    validate () {
      if (!this.phone) {
        Toast('Masukkan nomor handphone dengan awalan 08')
        return false
      }
      if (!validatePhoneNumber(this.phone)) {
        Toast('Nomor handphone salah')
        return false
      }
      if (!this.password) {
        Toast('Masukkan 8 digit angka password')
        return false
      }
      if (!validatePassword(this.password)) {
        Toast('Password salah')
        return false
      }
      return true
    },
    onSubmit () {
      if (!this.validate()) return
      if (this.flag) return
      this.flag = true
      const postData = {
        lodrginType: 1,
        usdrerPhone: this.phone,
        usdrerPassword: this.password
      }
      request.post('usdrerLogin', postData)
        .then(res => {
          this.$store.commit('recordInfo', {
            type: 'userInfo',
            value: res
          })
          Cookies.set('userInfo', JSON.stringify(res))
          const { redirectUrl = '/' } = this.$route.query
          this.$router.push(redirectUrl)
        })
        .finally(() => {
          this.flag = false
        })
    },
    goMessageLogin () {
      const { query } = this.$route
      this.$router.push({
        path: '/messageLogin',
        query
      })
    },
    goRegister () {
      const { query } = this.$route
      this.$router.push({
        path: '/register',
        query
      })
    }
  }
}
</script>

<style scoped lang="less">
.login {
  min-height: 100vh;
  background: url(../assets/img/forget-password-bg.jpg) no-repeat;
  background-size: cover;
}

.login-body {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 100px;
}

.header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 73px 30px 0;

  p {
    font-weight: 600;

    &:first-child {
      font-size: 40px;
      color: #4837BC;
    }

    &:last-child {
      font-size: 32px;
      color: #F9F9F9;
    }
  }
}

.input-block {
  display: flex;
  align-items: center;
  width: 570px;
  border-bottom: 2px solid rgba(72,55,188,.21);

  &:nth-of-type(n+2) {
    margin-top: 55px;
  }

  img {
    width: 28px;
    height: 34px;
  }

  input {
    flex: 1;
    height: 100px;
    line-height: 100px;
    border: none;
    padding-left: 20px;
    font-size: 30px;
    color: #333;
    background-color: transparent;

    &::placeholder {
      color: #737B91;
      font-size: 24px;
    }

    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}

.password {
  -webkit-text-security: disc;
}

.forget-password {
  margin-top: 34px;
  font-size: 32px;
  color: #737B91;
}

.login-btn {
  width: 100%;
  height: 120px;
  line-height: 120px;
  box-shadow: 0 8px 12px 0 rgba(0,0,0,0.1);
  border-radius: 138px;
  box-sizing: border-box;
  font-size: 32px;
  font-weight: 600;
  color: #FFF;
  text-align: center;

  &:first-of-type {
    margin-top: 68px;
    background: linear-gradient(315deg,#938BD9 0%,#F2F2F2 100%);
  }

  &:last-of-type {
    margin-top: 34px;
    background: linear-gradient(100deg,#6223AE 0%,rgba(161,226,226,0.11) 100%);
  }
}
</style>
